import { Image, Row, Col, Button } from 'react-bootstrap'
import topImage from '../assets/Alexis Davidson.png'
import logo_1 from '../assets/trust/aka.png'
import logo_2 from '../assets/trust/mazeverse.png'
import logo_3 from '../assets/trust/musure_world.png'
import logo_4 from '../assets/trust/pap.png'
import logo_5 from '../assets/trust/porkers.png'
import logo_6 from '../assets/trust/mystic.png'
import {Link} from 'react-router-dom'
import YouTube from 'react-youtube'

const Top = () => {
    const opts = {
    //   height: '668',
    //   width: '1024',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        // autoplay: 1,
      },
    };

    const buttonCallOnClick = () => {
        var ex = document.getElementById('ex');
        ex.click();
    }
    

    const buttonWorkOnClick = () => {
        var ex = document.getElementById('projectsLink');
        ex.click();
    }
    
    return (
        <Row className="topBg" id="top">
            <Row className="top m-0">
                <Col className="my-auto p-0 mx-0">
                    <Row className="p-0 m-0">
                        <div className="topTitle">
                            THE ROCKET
                            <br/><span className="web3">AI</span> AGENCY
                        </div>
                    </Row>
                    <Row className="p-0 m-0">
                        <Col className="topDescription col-8 col-lg-7">
                        We empower your AI dreams. With expertise in software, Artificial Intelligence, MLMs, we're your partner in realizing the full potential of AI. 
                            <br/><br/>
                            Let's make it happen together.
                        </Col>
                    </Row>

                    <Row className="p-0 m-0">
                        <Col className="m-0 p-0 mt-lg-4 mt-5 d-flex justify-content-start">
                            <Button onClick={buttonCallOnClick} className="callButton">Get a FREE Quote</Button>
                            <Button onClick={buttonWorkOnClick} className="checkWorkButton">Check our Work</Button>
                        </Col>
                    </Row>
                </Col>
                {/* <video id="vid" loop autoPlay muted className="homeBoxImage" >
                    <source src="/6.mp4" type="video/mp4"/>
                </video> */}
            </Row>

            <Row className="p-0 mx-0 scrollArrowRow">
                <a href="#trust" className="scrollArrow">↓</a>
            </Row>

            {/* VIDEO */}
            {/* <Row className="trust mx-0 p-0" id="trust">
                <Row className="p-0 videoMargin">
                    <h1>Meet Alexis, Talent at Linko Labs</h1>
                    <div className="videoWrapper">
                        <YouTube videoId="tHwjBYjpXxM" opts={opts} />
                    </div>
                </Row>
            </Row> */}
{/* 
            <Row className="trust mx-0 p-0">
                <Row className="p-0 trustMargin">
                    <div className="sectionTitle reveal">
                        They trust us
                    </div>
                </Row>
                <Row className="trustLogos d-none d-xl-block m-0 justify-content-center align-items-center reveal">
                    <Image src={logo_1} className="trustLogo" />
                    <Image src={logo_2} className="trustLogo" />
                    <Image src={logo_3} className="trustLogoMusure" />
                    <Image src={logo_4} className="trustLogo" />
                    <Image src={logo_6} className="trustLogo" />
                </Row>
                <Row className="trustLogos d-xl-none justify-content-center align-items-center reveal">
                    <Col className="col-6">
                        <Image src={logo_1} className="trustLogo" />
                    </Col>
                    <Col className="col-6">
                        <Image src={logo_3} className="trustLogoText" />
                    </Col>
                    <Col className="col-6">
                        <Image src={logo_6} className="trustLogo" />
                    </Col>
                    <Col className="col-6">
                    <Image src={logo_4} className="trustLogo" />
                    </Col>
                </Row>
            </Row> */}
        </Row>
    );
}
export default Top